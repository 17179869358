import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import CaseStudySummary from '../../components/CaseStudySummary';
import LayoutBlogPost from '../../components/LayoutBlogPost';
import SEO from '../../components/SEO';
import withI18n from '../../utils/hocs';

const CaseStudyAscenzaLocalSitesPageEn = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "blog/ascenza-local-sites/hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      wireframesImage: file(relativePath: { eq: "blog/ascenza-local-sites/wireframes.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      hp1Image: file(relativePath: { eq: "blog/ascenza-local-sites/hp1.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      hp2Image: file(relativePath: { eq: "blog/ascenza-local-sites/hp2.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      hp3Image: file(relativePath: { eq: "blog/ascenza-local-sites/hp3.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      productsImage: file(relativePath: { eq: "blog/ascenza-local-sites/products.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      productImage: file(relativePath: { eq: "blog/ascenza-local-sites/product.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      cms1Image: file(relativePath: { eq: "blog/ascenza-local-sites/cms1.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      cms2Image: file(relativePath: { eq: "blog/ascenza-local-sites/cms2.png" }) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
      gatsbyArchitectureImage: file(
        relativePath: { eq: "blog/ascenza-local-sites/gatsby-architecture.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid_noBase64
          }
          fixed(width: 1500) {
            src
          }
        }
      }
    }
  `);

  return (
    <LayoutBlogPost
      title="Case Study: ASCENZA local websites"
      date="September 28, 2020"
      heroImageFluid={data.heroImage.childImageSharp.fluid}
    >
      <SEO
        title="ASCENZA local websites - Mosca Digital"
        description="Case study of ASCENZA local websites"
        url="https://moscadigital.pt/en/case-study-ascenza-local-sites"
        imageUrl={data.hp1Image.childImageSharp.fixed.src}
      />
      <CaseStudySummary
        images={[
          data.hp1Image.childImageSharp,
          data.hp2Image.childImageSharp,
          data.hp3Image.childImageSharp,
          data.productsImage.childImageSharp,
          data.productImage.childImageSharp,
        ]}
        clientName="ASCENZA"
        scope="Websites, CMS and data migration"
        technologies={
          <>
            <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
              Gatsby
            </a>
            ,{' '}
            <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
              React
            </a>
            ,{' '}
            <a href="https://www.sanity.io/" target="_blank" rel="noreferrer">
              Sanity
            </a>
            ,{' '}
            <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
              Netlify
            </a>
            ,{' '}
            <a href="https://www.sketch.com/" target="_blank" rel="noreferrer">
              Sketch
            </a>
            ,{' '}
            <a href="https://www.invisionapp.com/" target="_blank" rel="noreferrer">
              Invision
            </a>
          </>
        }
        challenges="Multiple existing websites, common codebase, data migration, multiple languages, complex CMS, high performance"
        websites={
          <>
            <a href="https://ascenza-pt-ae4wj3jn.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.pt
            </a>
            ,{' '}
            <a href="https://ascenza-es-r44mjt34.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.es
            </a>
            ,{' '}
            <a href="https://ascenza-fr-pu23hpyg.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.fr
            </a>
            ,{' '}
            <a href="https://ascenza-it-35k7uvx7.netlify.app/" target="_blank" rel="noreferrer">
              www.ascenza.it
            </a>
          </>
        }
      ></CaseStudySummary>
      <p className="first-letter">
        ASCENZA, a multi-national company in the crop protection sector, came to us with a very
        interesting challenge: they were going through a rebranding and they wanted to use this as
        an opportunity to create new websites for the countries they were present in, four of which
        (Portugal, Spain, France and Italy) already had websites whose content should be kept.
      </p>
      <h3>Discovery and Wireframing</h3>
      <p>
        Since the existing websites had as much in common as they had differences, the first step
        was to analyse the existing websites and find a structure for the new website where the
        content from all these different websites could live. By using{' '}
        <a href="https://www.sketch.com/" target="_blank" rel="noreferrer">
          Sketch
        </a>{' '}
        to design wireframes and{' '}
        <a href="https://www.invisionapp.com/" target="_blank" rel="noreferrer">
          Invision
        </a>{' '}
        to share them, we managed to quickly iterate together with the relevant country teams to
        find a solution that worked for them.
      </p>
      <figure>
        <a href={data.wireframesImage.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.wireframesImage.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>An example of one the wireframes designed</figcaption>
      </figure>
      <p>
        Once we were confident in our new website structure, we started working on the website
        design.
      </p>
      <figure>
        <a href={data.hp2Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.hp2Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>The mockup corresponding to the wireframe above</figcaption>
      </figure>
      <h3>CMS and data migration</h3>
      <p>
        With the website structure mostly decided, we started working on the CMS (Content Management
        System) and on importing data from the old websites to the new.
      </p>
      <p>
        For the CMS we decided to use{' '}
        <a href="https://www.sanity.io/" target="_blank" rel="noreferrer">
          Sanity
        </a>
        , because it's focus on structured data perfectly matched the type of data ASCENZA had.
        Also, Sanity has a very intuitive and performant interface for editors, and it's highly
        customizable, which allowed us to build the CMS according to the editor's needs.
      </p>
      <figure>
        <a href={data.cms1Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.cms1Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Screenshot from the CMS we built for ASCENZA</figcaption>
      </figure>
      <figure>
        <a href={data.cms2Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.cms2Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Screenshot from the CMS's article edition interface</figcaption>
      </figure>
      <p>
        In parallel to the CMS, we started working on the scripts to convert the data from each of
        the 4 websites' old format to the new websites' format, and import it. We also used this
        importation step to clean up some of the data, such as replacing instances of the word Sapec
        (our client's old name) with ASCENZA, or replacing all caps text (a bad practice, e.g. "ALL
        CAPS TEXT") with sentence case text (e.g. "Sentence case text") where applicable.
      </p>
      <p>
        Once the CMS and importing scripts were tested and validated by ASCENZA, we made the final
        importation and delivered the CMS to ASCENZA, so they could start improving the imported
        data and adding new data for the new website where needed.
      </p>
      <h3>Implementation and delivery</h3>
      <p>
        While our client was working on improving the data in the CMS, and now that the designs had
        been finished and validated, we entered the last phase of the project, actually building the
        new websites.
      </p>
      <p>
        We decided to use{' '}
        <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
          Gatsby
        </a>
        , an open-source{' '}
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          React
        </a>{' '}
        based framework, to build the websites. Contrary to traditional frameworks like Wordpress
        that require a full server to run a webserver that builds the pages on each request (and so
        are slow and hard to scale and secure), sites built with Gatsby are just static files that
        can be deployed very easily on a service like{' '}
        <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
          Netlify
        </a>
        , and thus have an amazing performance, security and scalability.
      </p>
      <figure>
        <a href={data.gatsbyArchitectureImage.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.gatsbyArchitectureImage.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Architecture of a Gatsby/Netlify/Sanity website</figcaption>
      </figure>
      <p>
        In order to get something in front of our client as soon as possible so it could be
        validated and tested, we focused on implementing one part of the website at a time, starting
        with the main pages such as the homepage, products page and contacts page. This also meant
        that even with a few pages and functionalities left to implement, we already had a complete
        website that could have been launched earlier than the deadline should ASCENZA want it.
      </p>
      <p>
        Once ASCENZA was happy with the data, and we were done implementing the website, we were
        ready for launch.
      </p>
      <p>
        First, this meant implementing error reporting so that we would know if any error happened
        in production, and monitoring so we would know if the website went down for some reason. We
        also implemented a backup strategy for the data in the CMS, and finally we setup analytics
        on the website so that ASCENZA could know how many users were visiting the website, where
        they came from and what they were looking for.
      </p>
      <p>
        Once that was done, we are happy to say that the launch was pretty uneventful, on the
        technical side of things at least. Our staging environment, where ASCENZA had been
        validating the websites, was already on Netlify, same as our production environment, so
        launching just meant pointing the new domains to Netlify, and we were confident that not
        only the website would be working just as during validation, but that we could handle
        whatever traffic that would be thrown at us due to the amazing duo of Gatsby+Netlify.
      </p>{' '}
      <figure>
        <a href={data.hp1Image.childImageSharp.fixed.src} data-attribute="SRL">
          <Img fluid={data.hp1Image.childImageSharp.fluid} alt="" />
        </a>
        <figcaption>Homepage's screenshot of one of the implemented websites</figcaption>
      </figure>
      <h3>Conclusion</h3>
      <p>
        In August 5, 2020, just 5 months after deployment had started, ASCENZA saw 4 new secure,
        performant and scalable websites launched (
        <a href="https://ascenza-pt-ae4wj3jn.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.pt
        </a>
        ,{' '}
        <a href="https://ascenza-es-r44mjt34.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.es
        </a>
        ,{' '}
        <a href="https://ascenza-fr-pu23hpyg.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.fr
        </a>
        ,{' '}
        <a href="https://ascenza-it-35k7uvx7.netlify.app/" target="_blank" rel="noreferrer">
          www.ascenza.it
        </a>
        ), full with content imported (and improved) from their old websites, and with a powerful
        and easy to use CMS that would allow them control over the content of their websites long
        after deployment.
      </p>
      <p>We're proud to have been chosen by ASCENZA, and we're proud of what we delivered.</p>
      <p>
        And every project teaches us new lessons that we take and apply on the next ones to deliver
        even better solutions even faster.
      </p>
      <p>Could your project be the next?</p>
    </LayoutBlogPost>
  );
};

export default withI18n(CaseStudyAscenzaLocalSitesPageEn, 'en');
