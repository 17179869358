import React from 'react';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image/withIEPolyfill';
import { FluidObject, FixedObject } from 'gatsby-image';

interface CaseStudySummaryProps {
  images: Array<{
    fluid: FluidObject;
    fixed: FixedObject;
  }>;
  clientName: string;
  scope: string;
  technologies: React.ReactElement;
  challenges: string;
  websites: React.ReactElement;
}

const CaseStudySummary = ({
  images,
  clientName,
  scope,
  technologies,
  challenges,
  websites,
}: CaseStudySummaryProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="case-study-summary">
      <h3>{t('case_study_summary.title', 'Resumo')}</h3>
      <div className="carousel" data-slick='{"slidesToShow": 1, "dots": true, "draggable": false}'>
        {images.map((image, i) => (
          <div key={i} className="carousel-item">
            <figure>
              <a href={image.fixed.src} data-attribute="SRL">
                <Img fluid={image.fluid} alt="" />
              </a>
            </figure>
          </div>
        ))}
      </div>
      <h4>{t('case_study_summary.client', 'Cliente')}</h4>
      <p>{clientName}</p>
      <h4>{t('case_study_summary.scope', 'Âmbito')}</h4>
      <p>{scope}</p>
      <h4>{t('case_study_summary.technologies', 'Tecnologias')}</h4>
      <p>{technologies}</p>
      <h4>{t('case_study_summary.challenges', 'Desafios')}</h4>
      <p>{challenges}</p>
      <h4>{t('case_study_summary.websites', 'Websites')}</h4>
      <p>{websites}</p>
    </div>
  );
};

export default CaseStudySummary;
