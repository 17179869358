import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFluidObject } from 'gatsby-background-image';
import { SRLWrapper } from 'simple-react-lightbox';

import Layout from './Layout';
import Hero from './Hero';
import LocalizedLink from './LocalizedLink';

interface LayoutBlogPostProps {
  title: string;
  date: string;
  heroImageFluid: IFluidObject;
  children: React.ReactNode;
}

const LayoutBlogPost = ({
  title,
  date,
  heroImageFluid,
  children,
}: LayoutBlogPostProps): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Layout className="blog-post">
      <Hero bgImageFluid={heroImageFluid} title={title} suptitle={date} />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <article className="post-single">
                <div className="post-body">
                  <SRLWrapper options={{ thumbnails: { showThumbnails: false } }}>
                    {children}
                  </SRLWrapper>
                </div>
              </article>
              <hr />
              <div className="title text-center talk-to-us">
                <h3>
                  {t(
                    'article.talk_to_us.title',
                    'Tem algum problema com o qual o possamos ajudar?',
                  )}
                </h3>
                <LocalizedLink className="btn btn-color btn-lg btn-shadow" to="/#contact-us">
                  {t('article.talk_to_us.button', 'Fale connosco')}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LayoutBlogPost;
